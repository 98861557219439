import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()

  const contentData = [
    {
      icon: require("assets/images/invest-content-ico-1.svg").default,
      title: t("Podpisanie umowy"),
      description: t(
        "Podpisujesz umowę na zakup udziałów w naszym projekcie deweloperskim."
      ),
    },
    {
      icon: require("assets/images/invest-content-ico-2.svg").default,
      title: t("Wpłata kapitału"),
      description: t(
        "Opłacasz wybraną przez siebie ilość udziałów, które chcesz objąć."
      ),
    },
    {
      icon: require("assets/images/invest-content-ico-3.svg").default,
      title: t("Wypłata zysku"),
      description: t(
        "Przez cały okres obowiązywania umowy, co kwartał wypłacamy Twój zysk"
      ),
    },
  ]

  return (
    <section className="invest-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <h3>
              {t(
                "Zainwestuj w spółkę deweloperską! Zostań udziałowcem i ciesz się gwarantowanym notarialnie, wypłacanym kwartalnie zyskiem."
              )}
            </h3>
            <p>
              {t(
                "Inwestowanie w spółki deweloperskie realizujące inwestycje mieszkaniowe to bezpieczna i zyskowna alternatywa dla obligacji czy lokat. Nawet w dobie kryzysu popyt na mieszkania jest bardzo duży - według różnych szacunków w Polsce, pomimo boomu inwestycyjnego, nadal brakuje od 1 do 3 milionów mieszkań."
              )}
            </p>
            {contentData.map((item, index) => (
              <div className="invest-content__item" key={index}>
                <div className="invest-content__item-icon">
                  <img src={item.icon} alt={item.title} />
                </div>
                <div>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-5">
            <img
              className="invest-content__image"
              src={require("assets/images/invest-content-image.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
