import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

const Baner = () => {
  const { t } = useLocale()

  return (
    <section className="invest-baner">
      <div className="container-fluid">
        <h2
          dangerouslySetInnerHTML={{
            __html: t(
              "Zainwestuj w projekt deweloperski, <br /> poznaj ofertę Trust Finance."
            ),
          }}
        ></h2>
        <Link to={t("/kontakt/")} className="button button--circle">
          {t("więcej")}
        </Link>
      </div>
    </section>
  )
}

export default Baner
