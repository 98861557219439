import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Baner } from "page_components/invest"

const Invest = ({ location }) => {
  const title = "Inwestuj"

  return (
    <Layout
      location={location}
      seo={{
        title: "Zainwestuj w projekt deweloperski",
        description:
          "Zainwestuj w projekt deweloperski poprzez inwestycję w equity spółki celowej. Prosta umowa potwierdzona notarialnie i gwarantowany zysk wypłacany co kwartał.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Baner />
    </Layout>
  )
}

export default Invest
